.image-container {
  position: relative;
  display: flex; /* This helps center the image */
  justify-content: center;
  align-items: center;
  height: 50px;
}

.table-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
}

table tbody tr td {
  vertical-align: middle; /* Align content vertically in the middle of the row */
  padding: 8px; /* Consistent padding for all cells */
}

td:last-child {
  padding: 8px;
  text-align: right; /* Right-align for numeric values */
  vertical-align: middle; /* Ensure vertical alignment */
}

th,
td {
  vertical-align: middle;
}

.image-hover {
  display: none;
  position: absolute;
  top: -10px;
  left: 60px;
  z-index: 1000;
  border: 1px solid #ddd;
  background-color: #fff;
}

.image-container:hover .image-hover {
  display: block;
}

.hover-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}
