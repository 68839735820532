.image-container {
    position: relative;
    display: inline-block;
}

.table-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    cursor: pointer;
}

.image-hover {
    display: none;
    position: absolute;
    top: -10px;
    left: 60px;
    z-index: 1000;
    border: 1px solid #ddd;
    background-color: #fff;
}

.image-container:hover .image-hover {
    display: block;
}

.hover-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
}