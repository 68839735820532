/* Menu List Styles */
.menu-list {
  height: 450px;
  overflow-y: auto;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 12px;
}

.menu-list::-webkit-scrollbar {
  width: 6px;
}

.menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.menu-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Menu Item Styles */
.menu-item {
  transition: all 0.2s ease;
}

.menu-item-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
}

/* Hover Effects */
.hover-item {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
}

.hover-item:hover {
  transform: translateX(5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hover-shadow {
  transition: all 0.3s ease;
}

.hover-shadow:hover {
  transform: translateY(-5px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

/* Outlet Details */
.outlet-details {
  font-size: 0.9rem;
}

/* Menu Items List */
.menu-items-list .form-check {
  padding: 8px;
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
}

.menu-items-list .form-check:hover {
  background-color: #f8f9fa;
}

/* Selected Item */
.selected-item {
  transition: all 0.2s ease;
}

.selected-item:hover {
  background-color: #e9ecef !important;
}

/* Custom Manage Button */
.custom-manage-btn {
  background-color: #034159 !important;
  border-color: #034159 !important;
  color: white !important;
  transition: all 0.3s ease !important;
}

.product-name {
  font-size: 0.8rem;
  font-weight: 600;
}

.custom-manage-btn:hover {
  background-color: #ff4500 !important;
  border-color: #ff4500 !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(255, 100, 46, 0.2);
}

.custom-manage-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(255, 100, 46, 0.1);
}

/* Modal Styles */
.menu-management-modal {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.menu-management-modal .modal-content {
  border: none;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.menu-management-modal .modal-header {
  border-bottom: 1px solid #f0f0f0;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 12px 12px 0 0;
}

.menu-management-modal .modal-body {
  padding: 1.5rem;
}

/* Middle Section */
.middle-section {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  height: fit-content;
  margin: auto 0;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.5);
  border: 1px solid #e9ecef;
}

/* Item Count Badge */
.item-count {
  background: #459cf4;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
}

.outlet-name {
  font-weight: 600;
  font-size: 1.1rem;
  color: #0247bf;
  text-transform: capitalize;
  letter-spacing: 0.05rem;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
}

.bulk-action {
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease;
}

.bulk-action:hover {
  transform: scale(1.1);
}

.menu-item.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.bulk-action {
  cursor: pointer;
}

.bulk-action.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-saving:disabled {
  background-color: #609efacb !important; /* Bootstrap primary color */
  border-color: #6ca3f6a6 !important;
  opacity: 0.65;
  color: white !important;
}

.saving-text {
  color: white !important;
}

.spinner-border {
  border-color: white;
  border-right-color: transparent;
}

.menu-items-count {
  display: inline-flex;
  align-items: center;
  /* gap: 2px; */
  font-weight: 400;
  color: #6c757d;
  font-size: 0.8rem;
}

.check-icon {
  color: #198754;
  font-size: 18px;
}

.count-badge {
  background-color: #198754;
  color: white;
  border-radius: 50%;
  min-width: 22px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  padding: 0 4px;
}

/* Outlet Status Switch */
.outlet-switch {
  --switch-width: 1.5em;
  --switch-height: 0.8em;
}

.outlet-switch .form-check-input {
  width: var(--switch-width) !important;
  height: var(--switch-height) !important;
  cursor: pointer;
  background-color: #d7d2d2fb;
  border-color: #e6dede;
  position: relative;
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

.outlet-switch .form-check-input:checked {
  background-color: #198754;
  border-color: #198754;
  transition: all 0.2s ease-in-out;
}

.outlet-switch .form-check-input:focus {
  border-color: transparent;
  box-shadow: none;
}

.outlet-switch .form-check-input:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Badge Styles */
.badge {
  font-weight: 500;
  font-size: 0.75rem;
  padding: 0.35em 0.65em;
  transition: all 0.3s ease;
}

.badge.bg-success {
  background-color: #19875450 !important;
  color: #198754;
}

.badge.bg-danger {
  background-color: #c44d5b50 !important;
  color: #dc3545;
}

/* Card Title */
.card-title {
  margin-bottom: 0 !important;
}

.card-title .text-primary {
  font-weight: 600;
  font-size: 1.1rem;
}

/* Confirmation Modal Styles */
.modal-sm .modal-content {
  border: none;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.modal-sm .alert {
  margin-bottom: 0;
  font-size: 0.85rem;
}

.modal-sm .modal-header {
  border-bottom: 1px solid #f0f0f0;
  padding: 1rem 1.5rem;
}

.modal-sm .modal-footer {
  border-top: none;
  padding: 1rem 1.5rem;
}

.modal-sm .modal-body {
  padding: 1.5rem;
}

.alert-dialog-activate {
  background-color: #00780a;
  border-color: #dee6df;
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 10px;
}

.alert-dialog-deactivate {
  background-color: #c44d5b;
  border-color: #dee6df;
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 10px;
}
